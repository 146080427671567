<template>
  <div>
    <a-avatar
      :size="size ? size : 'default'"
      v-if="thumbnail"
      :src="thumbnail.url"
    />
    <a-avatar :size="size ? size : 'default'" icon="loading" v-else />
  </div>
</template>

<script>
import externalTriggers from "../../../../../api/external-triggers";
export default {
    props: ['tenantId', 'smartButton', 'size'],
    data() {
        return {
            isLoadingThumbnail: false,
            thumbnail: null
        }
    },
    created() {
        this.getThumbnailUrl();
    },
    methods: {
        getThumbnailUrl() {
            let vm = this;
            vm.isLoadingThumbnail = true;
            externalTriggers.getTriggerFormFactorThumb(vm.smartButton.formFactorId).then(r => {
                vm.isLoadingThumbnail = false;
                vm.thumbnail = r.data;
            }).catch(e => {
                console.log(e);
            });
        }
    }
};
</script>

<style>
</style>