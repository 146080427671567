<template>
  <div class="view-admin-smart-button">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Page Header -->
    <div class="page-header" v-if="!isLoadingCombined">
      <smart-button-thumbnail style="margin-right: 20px;"
       :tenant-id="tenantId" :smart-button="triggerSource" size="large"></smart-button-thumbnail>

      <h1 class="page-title">Smart Button: {{ triggerSource.displayName }}</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type=""
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-button
          class="button-margin-left btn-rounded"
          icon="edit"
          size="large"
          type="primary"
          @click.prevent="edit"
          >Edit</a-button
        >

        <a-popconfirm
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Basic details -->
    <div class="basic-details" v-if="!isLoading">
      <h3>Details</h3>

      <div class="basic-details-inner">
        <p>
          <b>Assigned To:</b>
          {{
            triggerSource.presenceId
              ? getPresenceName(triggerSource.presenceId)
              : "Organisation"
          }}
        </p>
        <p v-if="triggerFormFactor">
          <b>Type:</b>
          {{ triggerFormFactor.displayName }}
        </p>
        <p style="margin-bottom: 0">
          <b>Default Location:</b> {{ location ? location.displayName : '-' }}
        </p>
      </div>
    </div>
    <!-- / Basic details -->

    <!-- Actions List -->
    <div class="actions-list">
      <!-- Loading actions -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading actions -->

      <div v-if="!isLoading">
        <h3>Actions</h3>
      </div>

      <!-- No actions -->
      <a-alert
        v-if="!isLoading && !actions.length"
        type="info"
        message="No actions to show for this smart button"
      />
      <!-- / No actions -->

      <!-- Loaded -->
      <div v-if="!isLoading && actions.length">
        <div
          v-for="(action, i) in actions"
          :key="i"
          class="action-list-item-wrapper"
        >
          <action-list-item :tenant-id="tenantId" :action="action"></action-list-item>
        </div>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / Actions List -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import externalTriggers from "../../../../api/external-triggers";
import ActionListItem from "./Show/ActionListItem.vue";
import SmartButtonThumbnail from "../../Integration/SmartButtons/SmartButtonListItem/SmartButtonThumbnail.vue";
export default {
  components: { LoadingScreen, ActionListItem, SmartButtonThumbnail },

  data() {
    return {
      isDeleting: false,

      isLoadingActions: false,
      actions: [],
    };
  },

  created() {
    if (!this.triggerSource) {
      this.$message.info("Smart button not found");
      this.$router.push("/admin/integration/smart-buttons");
    } else {
      this.getActions();
    }
  },

  methods: {
    goBack() {
      this.$router.push("/admin/integration/smart-buttons");
    },

    edit() {
      this.$router.push(
        "/admin/integration/smart-buttons/" + this.triggerSourceId + "/edit"
      );
    },

    getPresenceName(presenceId) {
      let presence = _.find(this.membership, { id: presenceId });
      if (presence) {
        return presence.displayName;
      }
      return "Unknown";
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      externalTriggers
        .deleteTriggerSource(vm.triggerSource.uniqueId)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Smart button deleted successfully");
          vm.$router.push("/admin/integration/smart-buttons");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting smart button");
        });
    },

    getActions() {
      let vm = this;
      vm.isLoadingActions = true;

      externalTriggers
        .getActionsForTriggerSource(this.tenantId, this.triggerSource.id)
        .then((r) => {
          vm.actions = r.data;
          vm.isLoadingActions = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingActions = false;
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
      locations: "locations",
      membership: "membership"
    }),

    ...mapGetters("adminSmartButtons", {
      triggerSources: "triggerSources",
      triggerFormFactors: 'triggerFormFactors'
    }),

    location() {
      return _.find(this.locations, { id: this.triggerSource.locationId });
    },

    triggerSourceId() {
      return this.$route.params.id;
    },

    triggerSource() {
      return _.find(this.triggerSources, (ts) => {
        return ts.id == this.triggerSourceId;
      });
    },

    isLoadingCombined() {
      return this.isLoading;
    },

    triggerFormFactor() {
      if (this.triggerFormFactors && this.triggerFormFactors.length) {
        return _.find(this.triggerFormFactors, {
          id: this.triggerSource.formFactorId,
        });
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.view-admin-smart-button {
  padding-bottom: 20px;

  .actions-list {
    margin-top: 30px;
  }

  .page-header {
    margin-bottom: 40px;
  }

  .page-title {
    margin-bottom: 0;
  }

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .basic-details-inner {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
  }

  .action-list-item-wrapper {
    margin-bottom: 15px;
  }
}
</style>