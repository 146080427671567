<template>
  <div class="action-list-item">
    <p><b>Event:</b> {{ triggerEvent }}</p>
    <p :style="actionParagraphStyle"><b>Action:</b> {{ triggeredAction }}</p>
    <p style="margin-bottom: 0" v-if="incidentType">
      <b>SOS Type:</b> {{ incidentType["displayName"] }}
    </p>
  </div>
</template>

<script>
import incidentTypes from "../../../../../api/incident-types";
export default {
  props: ["tenantId", "action"],
  data() {
    return {
      isLoadingIncidentType: false,
      incidentType: null,
    };
  },
  created() {
    if (
      this.action["action"]["$t"] ==
        "Soteria.Models.TriggerOrgSosAction, Soteria.Models" &&
      this.action["action"]["$v"]["incidentTypeId"]
    ) {
      this.loadIncidentType();
    }
  },
  methods: {
    loadIncidentType() {
      let vm = this;
      vm.isLoadingIncidentType = true;
      incidentTypes
        .getIncidentType(
          vm.tenantId,
          vm.action["action"]["$v"]["incidentTypeId"]
        )
        .then((r) => {
          vm.isLoadingIncidentType = false;
          vm.incidentType = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingIncidentType = false;
        });
    },
  },
  computed: {
    actionParagraphStyle() {
      if (!this.incidentType) {
        return {
          'marginBottom': 0,
        };
      } else {
        return {};
      }
    },

    triggerEvent() {
      let id = this.action.triggerEventId;
      if (id == 1) {
        return "Single click";
      }
      if (id == 2) {
        return "Double click";
      }
      if (id == 3) {
        return "Hold down";
      }
      return null;
    },

    triggeredAction() {
      let res = this.action["action"]["$t"];
      let action = res.replace(", Soteria.Models", "");

      if (action == "Soteria.Models.TriggerSelfTestAction") {
        return "Triggers a test action";
      }

      if (action == "Soteria.Models.TriggerOrgSosAction") {
        return "Triggers an SOS";
      }

      if (action == "Soteria.Models.TriggerPersonalEmergencyAction") {
        return "Triggers a personal emergency";
      }

      return action;
    },
  },
};
</script>

<style scoped lang="scss">
.action-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
}
</style>